

@media (min-width: 1200px) {
  body .home_slider_item {
    position: relative;
  }
  body .home_slider_item h2 {
    font-size: 30px;
    padding: 15px 30px;
  }
  body .home_slider_item img {
    height: 500px;
  }
  body .categories_main .category h2 {
    font-size: 25px;
  }
  body .faq_main .lst .wp-block-column h4 {
    font-size: 35px;
  }
}